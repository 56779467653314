/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React from 'react';
// eslint-disable-next-line max-len
import { AlertColor, Box, Button, CircularProgress, InputAdornment, Stack, TextField, Typography, useTheme } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { AddCoupomProps } from '@interfaces/Services';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ButtonIcon, Feedback, Price } from '@components';
import { useUser } from '@hooks/user';
import { useGlobal } from '@hooks/global';
import { ExpandedListValues } from '../../../components/expanded-list-values';

export const Coupon = () => {
    const user = useUser();
    const global = useGlobal();
    const { palette, typography } = useTheme();

    const [open, setOpen] = React.useState(false);
    const [openInput, setOpenInput] = React.useState(false);
    const [responseData, setResponseData] = React.useState({
        isLoaded: false,
        type: 'success' as AlertColor,
        message: '',
    });

    const { register, reset, handleSubmit, formState: { errors } } =
        useForm<AddCoupomProps>({
            mode: 'all',
        });

    const onSubmit: SubmitHandler<AddCoupomProps> = async (data) => {
        setResponseData((prev) => prev = {
            ...prev,
            isLoaded: true,
        });
        const response = await user.cart.coupon.add({
            ...data,
            cartType: global.app.view.application.type,
        });

        setResponseData({
            type: response?.response.type,
            isLoaded: false,
            message: response?.response.message,
        });
        setOpen(true);

        if (!response?.error) {
            await user.cart.load({
                cartType: global.app.view.application.type,
            });
        }
    };

    const handleRemoveCoupon = async () => {
        setResponseData((prev) => prev = {
            ...prev,
            isLoaded: true,
        });
        const response = await user.cart.coupon.remove();

        setResponseData({
            type: response?.response.type,
            isLoaded: false,
            message: response?.response.message,
        });
        setOpen(true);

        if (!response?.error) {
            setOpenInput(false);
            reset();
            await user.cart.load({
                cartType: global.app.view.application.type,
            });
        }
    };

    return (
        <>
            <Box>
                {!user.cart.list.data?.discount?.coupon ? (!openInput ?
                    <ExpandedListValues mainValue={{
                        title: 'Cupom',
                        value: <Button
                            onClick={() => setOpenInput(true)}
                            sx={{
                                fontSize: '13px',
                                px: 1,
                                py: 0.6,
                                backgroundColor: palette.lightBg,
                                fontWeight: typography.fontWeightSemiBold,
                                borderColor: '#EFEFF3 !important',
                            }}
                            variant='outlined'>
                            <I
                                style={{ marginRight: '8px' }}
                                icon={['far', 'ticket']} />
                            Possui um Cupom?
                        </Button>,
                    }} /> :
                    (
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Stack spacing={1}>
                                <TextField
                                    focused
                                    variant="outlined"
                                    id="outlined-basic"
                                    size='small'
                                    type={'text'}
                                    // helperText={errors.couponCode ?
                                    //     errors.couponCode.message :
                                    //     ''}
                                    error={!!errors.couponCode}
                                    placeholder='Código do cupom'
                                    sx={{
                                        '& > .MuiInputBase-root': {
                                            pr: 0.5,
                                            fontSize: '14px',
                                        },
                                    }}
                                    {...register('couponCode',
                                        { required: openInput },
                                    )}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <I
                                                    color={palette.darkGray}
                                                    icon={['far', 'ticket']} />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <Button
                                                type='submit'
                                                variant='contained'
                                                size='small'
                                                disabled={responseData.isLoaded}
                                                onClick={() => null}>
                                                <Box>
                                                    {responseData.isLoaded ?
                                                        <CircularProgress
                                                            color='inherit' size={14} /> :
                                                        'Aplicar'
                                                    }
                                                </Box>
                                            </Button>
                                        ),
                                    }} />
                                <Button
                                    type='button'
                                    variant='text'
                                    disabled={responseData.isLoaded}
                                    size='large'
                                    onClick={() => {
                                        reset();
                                        setOpenInput(false);
                                    }}>
                                Cancelar
                                </Button>
                            </Stack>
                        </form>
                    )) :
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Stack alignItems={'center'} spacing={1} direction={'row'}>
                            <I
                                style={{ fontSize: '16px', color: palette.darkGray }}
                                icon={['far', 'ticket']} />
                            <Typography
                                fontSize={'14px'}
                                fontWeight={typography.fontWeightSemiBold}>
                                {user.cart.list.data?.discount?.coupon?.code}
                            </Typography>
                            {responseData.isLoaded ?
                                <Box p={0.4}>
                                    <CircularProgress color='inherit' size={16} />
                                </Box> :
                                <ButtonIcon
                                    onClick={() => handleRemoveCoupon()}
                                    size={'small'} icon={'trash-can'} />
                            }
                        </Stack>
                        <Stack position={'relative'} alignItems={'center'} spacing={0.3} direction={'row'}>
                            <Price size='small' type={'SHOPPING'} value={user.cart.list.data.discount?.discountValueUsingCoupon} />
                            <Box sx={{ ...sxOverviewLine }}></Box>
                        </Stack>
                    </Stack>
                }
            </Box>
            <Feedback.MuiSnackbar
                open={open}
                severity={responseData.type}
                onClose={() => setOpen(false)}
                message={responseData.message}
            />
        </>
    );
};

const sxOverviewLine = {
    position: 'absolute',
    height: '1px',
    width: '100%',
    top: 0,
    bottom: 0,
    left: 0,
    margin: 'auto !important',
    backgroundColor: '#000',
};
