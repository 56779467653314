import { useTheme } from '@mui/material';
import { rem, styles } from '@utils';

export const sx = () => {
    const { palette, typography } = useTheme();
    const mediaQuery = styles.mediaQueryCSS();

    const css = {
        box: {
            wrapper: {
                container: {
                    backgroundColor: palette.common.white,
                    border: '1px solid ' + palette.cityLights,
                    borderRadius: rem(4),
                },
                values: {
                    px: mediaQuery.lg ? 0 : rem(15),
                    py: rem(12),
                },
                tabs: {
                    mt: 2,
                    borderTop: '1px solid #ECF0F1',
                    borderRadius: mediaQuery.lg ? rem(4) : 0,
                    backgroundColor: palette.common.white,
                },
            },
            tabs: {
                borderBottom: '1px solid #ECF0F1',
            },
            row: {
                padding: `${rem(13)} 0`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            },
            divider: {
                borderBottom: `1px dotted ${palette.darkGray}`,
            },
        },
        texts: {
            balanceTitle: {
                padding: `${rem(12)} 0`,
                fontSize: rem(15),
                fontWeight: typography.fontWeightBold,
            },
            key: {
                fontSize: rem(14),
                fontWeight: typography.fontWeightMedium,
            },
            info: {
                maxWidth: rem(332),
                fontSize: rem(12),
                color: palette.mediumGray,
                fontWeight: typography.fontWeightMedium,
            },
        },
        tabs: {
            'width': '100%',
            'height': '100%',
            'padding': `0 ${rem(15)}`,
            '& > .MuiTabs-scroller': {
                '& > .MuiTabs-indicator': {
                    display: 'none',
                },
                '& > .MuiTabs-flexContainer': {
                    '& > .MuiTab-root': {
                        'textTransform': 'none !important',
                        'fontSize': rem(14),
                        'padding': rem(8),
                        'fontWeight': typography.fontWeightMedium,
                        'color': palette.grey,
                        '&.Mui-selected': {
                            color: palette.primaryColor,
                            borderBottom: `${rem(2)} solid ${palette.primaryColor}`,
                            fontWeight: typography.fontWeightSemiBold,
                        },
                    },
                },
            },
        },
    };

    return { css };
};
