import { useTheme } from '@mui/material';
import { rem, styles } from '@utils';

export const sx = () => {
    const { palette, typography } = useTheme();
    const mediaQuery = styles.mediaQueryCSS();


    const css = {
        container: {
            px: mediaQuery.md ? rem(24) : '0 !important',
            pb: 2,
            minHeight: 'calc(100vh - 296px)',
        },
        stack: {
            paymentOptions: {
            },
            installments: {
                pb: rem(30),
            },
            listValues2: {
                borderTop: `${rem(1)} dashed ${palette.cityLights}`,
                px: rem(5),
                pb: mediaQuery.md ? rem(0) : rem(15),
            },
        },
        grid: {
            minHeight: 'calc(100vh - 312px)',
        },
        box: {
            toPaidWrapper: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'end',
            },
            wrapper: {
                position: 'relative',
                minHeight: 'calc(100vh - 245px)',
            },
            wrapperStore: {
                border: `${rem(1)} solid ${palette.cityLights}`,
                mb: 2,
            },
            storeHeader: {
                borderBottom: `${rem(1)} solid ${palette.cityLights}`,
                px: rem(15),
                py: rem(8),
            },
            dividerSection: {
                pl: mediaQuery.md ? 2 : 0,
                borderLeft: `${rem(1)} solid ${palette.cityLights}`,
                height: '100%',

            },
            wrapperSection: {
                pt: mediaQuery.md ? rem(15) : rem(16),
                px: mediaQuery.md ? 0 : rem(16),
                height: '100%',
            },
            divider: {
                borderBottom: `${rem(1)} solid ${palette.cityLights}`,
            },
            dividerResume: {
                borderBottom: `${rem(1)} dashed ${palette.cityLights}`,
            },
            dividerMobile: {
                width: '100%',
                mt: 0.5,
                height: rem(5),
                backgroundColor: `${palette.cityLights}`,
            },
            productInline: {
                p: rem(15),
            },
            address: {
            },
            total: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'end',
                justifyContent: 'center',
            },
        },
        icon: {
            squarePlus: {
                fontSize: rem(24),
                marginRight: rem(10),
                color: palette.customBlack,
            },
            paymentMenu: {
                fontSize: rem(18),
                color: palette.customBlack,
                marginRight: rem(10),
            },
        },
        select: {
            '& > .MuiSelect-select': {
                'padding': `${rem(10)} ${rem(20)}`,
                'fontSize': `${rem(14)}`,
            },
        },
        texts: {
            balanceInPoints: {
                fontWeight: typography.fontWeightMedium,
                fontSize: rem(12),
            },
            expandedValueText: {
                fontWeight: typography.fontWeightMedium,
                fontSize: rem(12),
            },
            expandedValueChildren: {
                fontWeight: typography.fontWeightBold,
                fontSize: rem(12),
            },
            expandedMainValueChildren: {
                fontWeight: typography.fontWeightBold,
                fontSize: rem(14),
            },
            yourBalance: {
                'fontSize': rem(14),
                'fontWeight': typography.fontWeightMedium,
                '& > span': {
                    fontWeight: typography.fontWeightBold,
                },
            },
            paymentMethodType: {
                'fontSize': rem(14),
                'fontWeight': typography.fontWeightSemiBold,
            },
        },
    };

    return { css };
};
