import { Container, Grid, IconButton, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { AvatarGroup, Image } from '@components';
import logo from '../../../../assets/img/logo.png';
import { files, styles } from '@utils';
import { sx } from './sx';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from '@static/routes.list';
import React from 'react';
import { useUser } from '@hooks/user';
import { useFilters } from '@hooks/filters';
import { useAds } from '@hooks/ads';
import { useGlobal } from '@hooks/global';
// import { StoreType } from '@types';
import { useCustomTheme } from '@hooks/custom-theme';

const SimpleHeader: React.FC = () => {
    const { customTheme } = useCustomTheme();
    const { palette } = useTheme();
    const { css } = sx();
    const user = useUser();
    const mediaQuery = styles.mediaQueryCSS();
    const { clearFilters } = useFilters();
    const ad = useAds();

    const [verifyRoute, setVerifyRoute] = React.useState(true);

    const logged = (
        user.info.isLoaded &&
        !(user.info.status === 403) &&
        user.info.data.validated
    );

    const navigate = useNavigate();
    const location = useLocation();
    const global = useGlobal();

    // Verificar o tipo de pagina e esconder o botão voltar
    React.useEffect(() => {
        switch (location.pathname) {
        case routes.auth.createAccount.successFinished:
            setVerifyRoute(false);
            break;

        default:
            break;
        }
    }, [location.pathname]);

    React.useEffect(() => {
        if (!location.pathname.includes('/resultados/busca/')) {
            clearFilters();
            if (ad.categories.list.history.length >= 1) {
                ad.categories.reset({ type: 'history' });
                ad.categories.reset({ type: 'categories' });
            }
        }
    }, [location.search]);

    React.useEffect(() => {
        // const prop = localStorage.getItem('storeType');

        // if (prop) {
        //     global.app.view.set(prop as StoreType);
        // } else {
        // }
        global.app.view.set('REDEMPTION');
    }, []);
    return (
        <Box sx={css.header.wrapper}>
            <Container maxWidth='lg' sx={css.header.container.wrapper}>
                <Grid container spacing={2} height='100%'>
                    <Grid
                        item xs={2}
                        display='flex'
                        alignItems='center'
                        justifyContent='flex-start'
                    >
                        {verifyRoute && !(location.pathname !== 'autenticando') && (
                            <IconButton onClick={() => navigate(-1)}>
                                <I
                                    icon={['far', 'arrow-left-long']}
                                    color={palette.dark}
                                    fontSize={20}
                                />
                            </IconButton>
                        )}
                    </Grid>
                    <Grid
                        item xs={8}
                        display='flex'
                        alignItems='center'
                        justifyContent='center'
                    >
                        <Box onClick={() => navigate('/')}>
                            <Image
                                src={!!customTheme.data.logo ?
                                    files.load(customTheme.data.logo, 'server') :
                                    logo}
                                alt='Logo'
                                objectFit='contain'
                                maxHeight={mediaQuery.md ? 42 : 29}
                            />
                        </Box>
                    </Grid>
                    {logged && (
                        <Grid
                            item xs={2}
                            display='flex'
                            alignItems='center'
                            justifyContent='flex-end'
                        >
                            <Box onClick={() => navigate('/')} sx={{ cursor: 'pointer' }}>
                                <AvatarGroup
                                    borderWidth='none'
                                    align={'bottom'}
                                    size={'tiny'}
                                    src={user.info.data.images ?
                                        files.load(
                                            user.info.data.images.avatar,
                                            'server',
                                        ) : ''}
                                />
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </Container>
        </Box>
    );
};

export { SimpleHeader };
