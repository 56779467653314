/* eslint-disable max-len */
import { Box, Button, InputAdornment, TextField, Typography } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import McLogo from '@img/mc-symbol.png';
import { Image } from '@components';
import { RechargeListValue } from '../../list-value';

interface RechargeStep2FormProps {
    ccnumber: string
}

interface RechargeStep2Props {
    onSubmitButton: () => void
    visible: boolean
}

export const RechargeStep2 = ({
    onSubmitButton,
    visible,
}: RechargeStep2Props) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<RechargeStep2FormProps>({
        mode: 'onSubmit',
    });

    const onSubmit: SubmitHandler<RechargeStep2FormProps> = async (data) => {
        console.log('DATA', data);
        onSubmitButton();
    };

    return (
        <Box p={4} display={visible ? 'block' : 'none'}>
            <Box>
                <Typography
                    color="dark"
                    variant="h6"
                    textAlign="left"
                    fontWeight="bold"
                    gutterBottom>
                    Informações do Cartão
                </Typography>
                <Typography
                    variant="subtitle2"
                    textAlign="left"
                    color="dark">
                    Digite as informações do seu cartão abaixo.
                </Typography>
            </Box>
            <Box component="form" pt={4} onSubmit={handleSubmit(onSubmit)}>
                <Box width="100%" mb={3}>
                    <TextField
                        fullWidth
                        focused
                        label='Número do cartão'
                        id="outlined-basic"
                        helperText=''
                        variant="outlined"
                        size="small"
                        error={!!errors.ccnumber}
                        {...register('ccnumber',
                            { required: true },
                        )}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Image
                                        src={McLogo}
                                        alt="Mastercard"
                                        maxHeight={20}
                                        objectFit='contain'
                                    />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                <Box>
                    <Box mb={1}>
                        <Typography
                            color="dark"
                            variant="body1"
                            textAlign="left"
                            fontWeight="bold">
                            Resumo do resgate
                        </Typography>
                    </Box>
                    <RechargeListValue
                        title='Crédito'
                        value='Presente Perfeito'
                        divider
                    />
                    <RechargeListValue
                        title='Valor em créditos'
                        value='R$ 50,00'
                        divider
                    />
                    <RechargeListValue
                        title='Valor em pontos'
                        value='50 pontos'
                        divider
                    />
                    <Box display="flex" justifyContent="flex-end" pt={4}>
                        <Button
                            type='submit'
                            variant='contained'
                            size='large'>
                            Continuar
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
