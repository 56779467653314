import React from 'react';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';


interface ExpandedListValuesProps {
    mainValue: {
        title: string;
        value: React.ReactNode;
    };
    expandedChildren?: React.ReactNode;
}

export const ExpandedListValues: React.FC<ExpandedListValuesProps> = ({
    mainValue, expandedChildren,
}: ExpandedListValuesProps) => {
    const [isExpanded, setIsExpanded] = React.useState(false);
    const { typography } = useTheme();
    return (
        <Box>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <Typography
                        sx={{
                            fontSize: '0.875rem',
                            fontWeight: typography.fontWeightMedium,
                        }}
                        variant='h6'>
                        {mainValue.title}
                    </Typography>
                    {!!expandedChildren &&
                        <I
                            cursor={'pointer'}
                            fontSize={'0.875rem'}
                            onClick={() => setIsExpanded(!isExpanded)}
                            icon={['fas', isExpanded ? 'circle-minus' : 'circle-plus']} />
                    }
                </Stack>
                {mainValue.value}
            </Stack>
            <Box sx={{
                mt: isExpanded ? 1 : 0,
                transition: 'all 0.1s',
            }}>
                {isExpanded && expandedChildren}
            </Box>
        </Box>
    );
};
