
interface VerifyIsWhiteProps {
    currentColor: string;
    finalColor: string;
}

export const verifyIsWhite = ({
    currentColor, finalColor,
}: VerifyIsWhiteProps) => {
    if (currentColor?.toLowerCase() === '#ffffff' ||
        currentColor?.toLowerCase() === '#fff') {
        return finalColor; // Retorna a cor especificada
    }
    return currentColor;
};
