/* eslint-disable max-len */
import { Box, Button, Stack, Typography } from '@mui/material';
import { RechargeListValue } from '../../list-value';

interface RechargeStep3Props {
    onSubmitButton: () => void
    handlePrevious: () => void
    visible: boolean
}

export const RechargeStep3 = ({ onSubmitButton, handlePrevious, visible }: RechargeStep3Props) => {
    const onSubmit = () => {
        onSubmitButton();
    };

    return (
        <Box p={4} display={visible ? 'block' : 'none'}>
            <Box mb={3}>
                <Typography
                    color="dark"
                    variant="h6"
                    textAlign="left"
                    fontWeight="bold"
                    gutterBottom>
                    Informações do Cartão
                </Typography>
                <Typography
                    variant="subtitle2"
                    textAlign="left"
                    color="dark">
                    Digite as informações do seu cartão abaixo.
                </Typography>
            </Box>
            <Box>
                <Box mb={1}>
                    <Typography
                        color="dark"
                        variant="body1"
                        textAlign="left"
                        fontWeight="bold">
                        Resumo do resgate
                    </Typography>
                </Box>
                <RechargeListValue
                    title='Número do cartão'
                    value='5114322075878579'
                    divider
                />
                <RechargeListValue
                    title='Crédito'
                    value='Presente Perfeito'
                    divider
                />
                <RechargeListValue
                    title='Valor em créditos'
                    value='R$ 50,00'
                    divider
                />
                <RechargeListValue
                    title='Valor em pontos'
                    value='50 pontos'
                    divider
                />
                <Stack direction="row" justifyContent="flex-end" spacing={2} pt={4}>
                    <Button
                        type='button'
                        variant='text'
                        size='large'
                        onClick={handlePrevious}>
                        Voltar
                    </Button>
                    <Button
                        type='button'
                        variant='contained'
                        size='large'
                        onClick={onSubmit}>
                        Confirmar Resgate
                    </Button>
                </Stack>
            </Box>
        </Box>
    );
};
