/* eslint-disable max-len */
import {
    Box,
    Button,
    Divider,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Grid from '@mui/material/Grid2';

import CppLogo from '@img/pp-logo.png';
import Cpp from '@img/cpp.png';
import { RadioCard } from '../../radio-card';
import { RechargeHero } from '../../hero';

interface RechargeScreenStep1Props {
    handleNext: () => void
}

export const RechargeScreenStep1 = ({ handleNext }: RechargeScreenStep1Props) => {
    const { palette } = useTheme();
    const matches = useMediaQuery('(min-width:600px)');

    return (
        <Box>
            <RechargeHero
                title='Recarregue o Seu Cartão Aqui'
                description='Se você tem um cartão pré-pago emitido, realize a recarga.'
                image={Cpp}
                paddingBottom={6}
            />
            <Box pt={3}>
                <Box pb={1}>
                    <Typography
                        color="dark"
                        variant={matches ? 'h6' : 'subtitle1'}
                        fontWeight="bold"
                        gutterBottom>
                        Selecione o seu cartão
                    </Typography>
                    <Typography
                        variant={matches ? 'subtitle2' : 'body2'}
                        color="dark">
                        Escolha para qual cartão a recarga será realizada.
                    </Typography>
                </Box>

                <Box py={2}>
                    <Grid container spacing={1}>
                        <Grid size={matches ? 1 : 4}>
                            <RadioCard
                                radioInput={{ checked: true }}
                                image={CppLogo}
                                title="Presente Perfeito"
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Divider sx={{ color: palette.lightBg }} />
                <Box display="flex" justifyContent="flex-end" py={2}>
                    <Button
                        type='button'
                        variant='contained'
                        size='large'
                        onClick={handleNext}>
                        Continuar
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};
