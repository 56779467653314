/* eslint-disable max-len */
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';

interface RechargeStep1FormProps {
    code: string
}

interface RechargeStep1Props {
    onSubmitButton: () => void
    handlePrevious: () => void
    visible: boolean
}

export const RechargeStep1 = ({ onSubmitButton, handlePrevious, visible }: RechargeStep1Props) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<RechargeStep1FormProps>({
        mode: 'onSubmit',
    });

    const onSubmit: SubmitHandler<RechargeStep1FormProps> = async (data) => {
        console.log('DATA', data);
        onSubmitButton();
    };

    return (
        <Box p={4} display={visible ? 'block' : 'none'}>
            <Box>
                <Typography
                    color="dark"
                    variant="h6"
                    textAlign="left"
                    fontWeight="bold"
                    gutterBottom>
                    Código de confirmação
                </Typography>
                <Typography
                    variant="subtitle2"
                    textAlign="left"
                    color="dark">
                    Um código de confirmação foi enviado para o seu email:<br />
                    <strong>jacksoncorrea@outlook.com.br</strong>
                </Typography>
            </Box>
            <Box component="form" pt={4} onSubmit={handleSubmit(onSubmit)}>
                <Box maxWidth={380}>
                    <TextField
                        fullWidth
                        focused
                        label='Digite o código'
                        id="outlined-basic"
                        helperText=''
                        variant="outlined"
                        error={!!errors.code}
                        {...register('code',
                            { required: true },
                        )} />
                </Box>
                <Stack direction="row" justifyContent="flex-end" spacing={2} pt={4}>
                    <Button
                        type='button'
                        variant='text'
                        size='large'
                        onClick={handlePrevious}>
                        Cancelar
                    </Button>
                    <Button
                        type='submit'
                        variant='contained'
                        size='large'>
                        Fazer Resgate
                    </Button>
                </Stack>
            </Box>
        </Box>
    );
};
