import { useTheme } from '@mui/material';

export const sx = () => {
    const { typography } = useTheme();

    const css = {
        button: {
            width: '100%',
            border: '1px solid #ECF0F1 !important',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: typography.fontWeightBold ?? 700,
            fontSize: '0.75rem',
            height: '5.625rem',
            lineHeight: 1.2,
        },
        icon: {
            fontSize: '1.125rem',
            marginBottom: '0.5rem',
        },
    };

    return { css };
};
