import { useTheme } from '@mui/material';

const sx = () => {
    const { typography, palette } = useTheme();
    const { pxToRem } = typography;

    const css = {
        filters: {
            buttons: {
                default: {
                    ...{
                        backgroundColor: palette.common.white,
                        borderWidth: pxToRem(1),
                        borderStyle: 'solid',
                        borderColor: palette.cityLights,
                        borderRadius: pxToRem(3),
                        fontSize: pxToRem(14),
                        color: palette.mediumGray,
                    },
                    '&:hover': {
                        backgroundColor: palette.common.white,
                        borderColor: palette.cityLights,
                        boxShadow: 'none',
                    },
                },
                active: {
                    ...{
                        backgroundColor: `${palette.primaryColor} !important`,
                        borderRadius: pxToRem(3),
                        color: palette.common.white,
                        borderColor: `${palette.primaryColor} !important`,
                    },
                    '&:hover': {
                        backgroundColor: `${palette.secondaryColor} !important`,
                        boxShadow: 'none',
                    },
                },
            },
        },
    };

    return { css };
};

export { sx };
