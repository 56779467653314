import React from 'react';
import { Box, Radio, Typography } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { sx } from './sx';
import { AddressBoxProps } from '@interfaces';
import { rem } from '@utils';

const AddressBox: React.FC<AddressBoxProps> = ({
    legend,
    title,
    mode,
    noIcon,
    onClick,
    radio,
    icon,
    hideLocationIcon,
    borderType,
    addressTag,
    children,
}: AddressBoxProps) => {
    const { css } = sx();

    const Icon = {
        add: ['fal', 'square-plus'],
        addError: ['fal', 'square-plus'],
        list: ['far', 'chevron-right'],
        edit: ['fal', 'pen-to-square'],
    };

    return (
        <Box
            sx={{ ...css.box.wrapper(borderType ? borderType : 'solid') }}
            component={radio ? 'label' : 'div'}
            htmlFor={radio?.id}
            onClick={onClick}>
            <Box sx={{ display: 'flex' }}>
                {!hideLocationIcon && (
                    <Box style={{ ...css.box.locationIcon }}>
                        <I
                            icon={icon ?? ['fas', 'location-dot']}
                            style={{ ...css.icon.location }} />
                    </Box>
                )}
                <Box sx={{ ...css.box.wrapperTexts }}>
                    <Box sx={{ ...css.box.texts }}>
                        {!!addressTag && (
                            <Box sx={css.addressTag.wrapper}>
                                <Typography
                                    variant='body1'
                                    component='h2'
                                    textTransform='uppercase'
                                    fontWeight='bold'
                                    fontSize={rem(12)}>
                                    {addressTag}
                                </Typography>
                            </Box>
                        )}
                        {title && (
                            <Typography
                                variant='body1'
                                sx={{ ...css.typography.title }}>
                                {title}
                            </Typography>)}
                        {legend && (
                            <Typography
                                variant='body2'
                                sx={{ ...css.typography.legend }}>
                                {legend}
                            </Typography>)}
                        {children && children}
                    </Box>
                </Box>
            </Box>
            <Box sx={{ ...css.box.iconAction }}>
                {!noIcon &&
                    <I
                        icon={Icon[mode] as any}
                        style={{ ...css.icon.mode }} />
                }
                {radio && (
                    <Radio
                        size='small'
                        {...radio}
                    />
                )}
            </Box>
        </Box>
    );
};

export { AddressBox };
