import { Box, Radio, Stack, Typography } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { sx } from './sx';

interface RadioPointsProps {
    valuePoints: number;
    disabled?: boolean;
    onlyPoints?: boolean;
}

export const RadioPoints: React.FC<RadioPointsProps> = ({
    disabled, valuePoints, onlyPoints,
}: RadioPointsProps) => {
    const { css } = sx();
    return (
        <Stack
            sx={{ ...css.stack.wrapper }}
            alignItems={'center'}
            direction={'row'}>
            <Box sx={{ ...css.box.radio, opacity: disabled ? 0.3 : 1 }}>
                <Radio disabled={disabled} checked={!disabled} />
            </Box>
            <Stack width={'100%'} spacing={1} py={2}>
                <Stack
                    justifyContent={'space-between'}
                    direction={'row'}
                    width={'100%'}
                    alignItems={'center'}>
                    <Typography sx={{ ...css.text.title, opacity: disabled ? 0.3 : 1 }}>
                        Pontos {(!disabled && !onlyPoints) && ' + Forma de Pagamento'}
                    </Typography>
                    {(disabled || onlyPoints) &&
                        <Typography sx={{ ...css.text.yourBalance }}>
                            Seu saldo: <span>{valuePoints} Pontos</span>
                        </Typography>
                    }
                </Stack>
                {disabled &&
                    <Box sx={{ ...css.box.alert }}>
                        <I
                            color='#D32F2F'
                            fontSize={18}
                            icon={['fas', 'circle-exclamation']} />
                        <Typography sx={{ ...css.text.alert }}>
                            Voce não tem pontos suficientes para pagar apenas com pontos
                        </Typography>
                    </Box>
                }
            </Stack>
        </Stack>
    );
};
