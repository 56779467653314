import { Box, Stack, Typography, useTheme } from '@mui/material';

interface RechargeListValueProps {
    title: string;
    value: string;
    divider?: boolean;
}

export const RechargeListValue = ({
    title,
    value,
    divider = true,
}: RechargeListValueProps) => {
    const { palette } = useTheme();
    return (
        <Box>
            <Stack py={1} direction="row" justifyContent="space-between">
                <Typography
                    color="dark"
                    variant="subtitle2"
                    textAlign="left">
                    {title}
                </Typography>
                <Typography
                    variant="subtitle2"
                    textAlign="left"
                    fontWeight="bold"
                    color="dark">
                    {value}
                </Typography>
            </Stack>
            {divider && (
                <Box sx={{
                    width: '100%',
                    height: '2px',
                    borderBottom: `1px dashed ${palette.cityLights}`,
                }} />
            )}
        </Box>
    );
};
