/* eslint-disable max-len */
import { Feedback } from '@components';
import { useGlobal } from '@hooks/global';
import { useUser } from '@hooks/user';
import { AlertColor, Box, CircularProgress, Typography, useTheme } from '@mui/material';
// import { StoreType } from '@types';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const AuthenticatedLoad = () => {
    const { palette } = useTheme();
    const [searchParams] = useSearchParams();
    const user = useUser();
    const global = useGlobal();
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [responseData, setResponseData] = React.useState({
        type: 'success' as AlertColor,
        message: '',
    });

    const applicationType = global.app.view.application;

    const handleClose = () => setOpen(false);


    const loadAPIS = async (token: string) => {
        if (token) {
            const response = await user.load({
                jwt: token,
            });

            if (response?.error) {
                setResponseData({
                    type: response?.response.type,
                    message: response?.response.message,
                });
                return;
            }
            // global.app.view.set(localStorage.getItem('storeType') as StoreType || 'SHOPPING');
            global.app.view.set('REDEMPTION');
            global.app.cookies.set('session', token);
            await user.favorites.load({
                favotireType: applicationType.type,
            });
            await user.cart.load({
                cartType: applicationType.type,
            });
            navigate('/');
        }
    };

    React.useEffect(() => {
        const token = searchParams.get('token');

        if (token) {
            loadAPIS(token);
        }
    }, []);

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box
                sx={(theme) => ({
                    boxShadow: 0,
                    bgcolor: '#fff',
                    color: 'grey.800',
                    p: 20,
                    mt: 10,
                    borderRadius: 2,
                    textAlign: 'center',
                    fontSize: '0.875rem',
                    fontWeight: '700',
                    display: 'flex', justifyContent: 'center',
                    ...theme.applyStyles('dark', {
                        bgcolor: '#101010',
                        color: 'grey.300',
                    }),
                })}
            >
                <Typography variant="subtitle1">
                    Aguarde...
                </Typography>
                <Box sx={{ display: 'flex', ml: 1, position: 'relative', top: '4px' }}>
                    <CircularProgress sx={{ color: palette.primaryColor }} size={20} />
                </Box>
            </Box>
            <Feedback.MuiSnackbar
                open={open}
                severity={responseData.type}
                onClose={handleClose}
                message={responseData.message}
            />
        </Box>
    );
};
