import {
    Box,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { Image } from '@components';

interface RechargeHeroProps {
    title: string;
    description: string;
    image: string;
    paddingBottom?: number;
}

export const RechargeHero = ({
    title,
    image,
    description,
    paddingBottom,
}: RechargeHeroProps) => {
    const { palette } = useTheme();
    const matches = useMediaQuery('(min-width:600px)');

    return (

        <Box sx={{
            backgroundColor: palette.snowWhite,
            borderRadius: 1,
            py: matches ? 6 : 5,
            pb: paddingBottom ? paddingBottom : 0,
        }}>
            <Stack
                direction={matches ? 'row' : 'column'}
                alignItems="center"
                justifyContent="center"
                spacing={matches ? 10 : 3}>
                <Box px={4}>
                    <Typography
                        color="dark"
                        variant={matches ? 'h4' : 'h6'}
                        textAlign={matches ? 'left' : 'center'}
                        fontWeight="bold"
                        gutterBottom>
                        {title}
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        textAlign={matches ? 'left' : 'center'}
                        color="dark">
                        {description}
                    </Typography>
                </Box>
                <Box>
                    <Image
                        src={image}
                        alt={title}
                        maxHeight={matches ? 303 : 180}
                        objectFit='contain'
                    />
                </Box>
            </Stack>
        </Box>
    );
};
