import { useTheme } from '@mui/material';

export const sx = () => {
    const { typography } = useTheme();

    const css = {
        box: {
            radio: {
                height: '60px',
                minWidth: '60px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
            alert: {
                backgroundColor: '#FDEDED',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                p: 1,
            },
        },
        stack: {
            wrapper: {
                border: '1px solid #ECF0F1 !important',
                pr: 2,
            },
        },
        text: {
            title: {
                fontWeight: typography.fontWeightBold,
                fontSize: '0.875rem',
            },
            yourBalance: {
                'fontSize': '0.875rem',
                'fontWeight': typography.fontWeightMedium,
                '& > span': {
                    fontWeight: typography.fontWeightBold,
                },
            },
            alert: {
                ml: 1,
                fontSize: '0.75rem',
                color: '#5F2120',
            },
        },
    };

    return { css };
};
