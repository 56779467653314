import {
    Box,
    Typography,
    useTheme,
} from '@mui/material';
import { Image } from '@components';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { InputHTMLAttributes } from 'react';

interface RadioCardProps {
    radioInput: InputHTMLAttributes<HTMLInputElement>;
    image: string;
    title: string;
}

export const RadioCard = ({ radioInput, image, title }: RadioCardProps) => {
    const { palette } = useTheme();

    return (
        <Box>
            <Box
                component="label"
                sx={{
                    'display': 'flex',
                    'flexDirection': 'column',
                    'border': '1px solid ' + palette.cityLights,
                    'borderRadius': '4px',
                    'overflow': 'hidden',
                    'position': 'relative',
                    ':checked ~ .mark-icon': {
                        'backgroundColor': palette.status.accepted,
                        'boxShadow': '0 4px 6px 0 rgba(0,0,0,0.2)',
                        '& > svg': {
                            path: {
                                fill: palette.common.white,
                            },
                        },
                    },
                }}>
                <input type='radio' hidden {...radioInput} />
                <Box sx={{
                    height: '20px',
                    width: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '100px',
                    border: '1px solid ' + palette.cityLights,
                    backgroundColor: palette.common.white,
                    position: 'absolute',
                    top: 5,
                    right: 5,
                }} className="mark-icon" >
                    <I icon={['fas', 'check']} fontSize={10} color={palette.clouds} />
                </Box>
                <Image
                    src={image}
                    alt={'cpp'}
                    maxHeight={74}
                    objectFit='cover'
                />
            </Box>
            <Typography
                mt={0.8}
                component="p"
                variant="caption"
                textAlign="center"
                fontWeight="semiBold"
                lineHeight={1.4}
                color="dark">
                {title}
            </Typography>
        </Box>
    );
};
