/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import {
    Box,
    // Button,
    Container,
    Drawer,
    Grid,
    IconButton,
    Skeleton,
    Stack,
    useTheme,
} from '@mui/material';
import { ButtonIcon, Display, Filters, Image, SearchForm } from '@components';
import { files, rem, styles } from '@utils';
import logo from '../../../../../assets/img/logo.png';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { sx } from './sx';
import React from 'react';
import { menu, translateStoreTypeMenu } from '@static/menus';
import { SubMenuButton } from '../../components/submenu-button';
import { slick } from '@static/slick-settings';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useLayout } from '@hooks/layout';
import { filtersList } from '@static/filters';
import { useFilters } from '@hooks/filters';
import { useAds } from '@hooks/ads';
import { useCustomTheme } from '@hooks/custom-theme';
import { GlobalContextProps, UserContextProps } from '@interfaces/Contexts';

interface HeaderProps {
    user: UserContextProps['info'];
    favorites: UserContextProps['favorites'];
    cart: UserContextProps['cart'];
    global: GlobalContextProps['app']['view']['application'];
}

const MobileHeader: React.FC<HeaderProps> = ({ user, favorites, cart, global }: HeaderProps) => {
    const { css } = sx();
    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    const { externalActions, clearFilters } = useFilters();
    const ad = useAds();

    const { customTheme } = useCustomTheme();
    const { layout } = useLayout();
    const { palette } = useTheme();
    const mediaQuery = styles.mediaQueryCSS();

    const logged = (
        user.isLoaded &&
        !(user.status === 403) &&
        user.data.validated
    );


    const [drawerFiltersMenu, setDrawerFiltersMenu] = React.useState(false);
    const [searchMode, setSearchMode] = React.useState(false);

    const handleToggleDrawerFiltersMenu = (type: 'open' | 'close') => {
        setDrawerFiltersMenu((prev) => {
            if (type === 'open') {
                return prev = true;
            }

            if (type === 'close') {
                return prev = false;
            }
            return prev;
        });
    };

    React.useEffect(() => {
        if (externalActions.status.valueRange.state ||
            externalActions.status.categoryList.state) {
            handleToggleDrawerFiltersMenu('close');
        }
    }, [
        externalActions.status.valueRange.state,
        externalActions.status.categoryList.state,
    ]);

    React.useEffect(() => {
        if (params.key) {
            setSearchMode(false);
        }
    }, [location.search]);

    React.useEffect(() => {
        if (!location.pathname.includes('/resultados/busca/')) {
            clearFilters();
            if (ad.categories.list.history.length >= 1) {
                ad.categories.reset({ type: 'history' });
                ad.categories.reset({ type: 'categories' });
            }
        }
    }, [location.search]);
    React.useEffect(() => {
        // services({ load: ['user'] });
    }, []);

    return (
        <React.Fragment>
            <Box sx={css.header.wrapper}>
                {!searchMode ?
                    <Container maxWidth='lg' sx={{ height: '100%' }}>
                        <Grid container spacing={0} height='100%'>
                            <Grid item xs={2}>
                                <Box height='100%' display='flex' alignItems='center'>
                                    <IconButton onClick={() => navigate(logged ?
                                        '/menu/usuario' :
                                        '/menu/deslogado')}>
                                        <I
                                            icon={['far', 'bars']}
                                            fontSize={rem(18)}
                                            color={palette.dark}
                                        />
                                    </IconButton>
                                </Box>
                            </Grid>
                            <Grid item xs={5}>
                                <Box
                                    height='100%'
                                    display='flex'
                                    alignItems='center'
                                    onClick={() => navigate('/')}>
                                    <Image src={!!customTheme.data.logo ?
                                        files.load(customTheme.data.logo, 'server') :
                                        logo} alt='Logo' objectFit='contain' maxHeight={28} />
                                </Box>
                            </Grid>
                            <Grid item xs={5}>
                                <Stack
                                    direction='row'
                                    alignItems='center'
                                    justifyContent='flex-end'
                                    height='100%'
                                    spacing={2}>
                                    <ButtonIcon
                                        onClick={() => setSearchMode(true)}
                                        icon={'search'}
                                        size='small'
                                        customColor={palette.dark} />
                                    {!layout.loading.value ?
                                        <>
                                            <ButtonIcon
                                                icon={'heart'}
                                                size='small'
                                                badge={favorites?.list?.data?.favorites?.length}
                                                active
                                                onClick={() => navigate('/favoritos')} />
                                            <ButtonIcon
                                                icon={'shopping-cart'}
                                                size='small'
                                                badge={cart?.list?.data?.totalProducts}
                                                active
                                                onClick={() => navigate('/meu-carrinho')} />
                                        </> :
                                        <>
                                            <Skeleton variant="circular" width={30} height={30} />
                                            <Skeleton variant="circular" width={30} height={30} />
                                        </>
                                    }
                                </Stack>
                            </Grid>
                        </Grid>
                    </Container> :
                    <Container maxWidth='lg' sx={{ height: '100%' }}>
                        <Stack
                            height={'100%'}
                            alignItems={'center'}
                            direction={'row'} spacing={2}>
                            <SearchForm />
                            <ButtonIcon
                                onClick={() => setSearchMode(false)}
                                icon={'xmark-circle'}
                                size='small'
                                customColor={palette.dark} />

                        </Stack>
                    </Container>
                }
            </Box>
            {location.pathname === '/' && (
                <Container maxWidth='lg'>
                    {layout.banners.carrousel.list.isLoaded ?
                        (layout.banners.carrousel.list.data.length > 0 && <Box mt={rem(20)} mb={rem(25)}>
                            <Display.Sliders.Home
                                settings={slick.settings.home.hero}
                                data={layout.banners.carrousel.list.data}
                                imageMaxHeight={!mediaQuery.sm ? 190 : 340}
                            />
                        </Box>) :
                        <Skeleton
                            variant='rectangular'
                            height={!mediaQuery.sm ? 190 : 340} />
                    }
                </Container>
            )}
            <Box sx={css.sub.wrapper}>
                <Container maxWidth='lg'>
                    <Stack direction='row' justifyContent='space-between'>
                        {menu(
                            location.pathname.includes('/resultados/busca') ?
                                ['categories'] :
                                ['filters'],
                        )
                            .map(({ id, title, icon, hash, href }, i, list) => {
                                const menuTitle = hash === 'storeType' ?
                                    translateStoreTypeMenu[global.type] :
                                    title;
                                return (
                                    <SubMenuButton
                                        key={id}
                                        icon={icon}
                                        title={menuTitle}
                                        showVerticalDivider={!(i + 1 === list.length)}
                                        onClick={() => {
                                            if (hash === 'filters') {
                                                // handleOpenDrawerFilterMenu();
                                                return handleToggleDrawerFiltersMenu(
                                                    'open',
                                                );
                                            } else {
                                                navigate(href ?? '');
                                            }
                                        }}
                                    />
                                );
                            })}
                    </Stack>
                </Container>
            </Box>

            {/* Filtros da página de resultados */}
            <Drawer
                anchor='right'
                open={drawerFiltersMenu}
                onClose={() => handleToggleDrawerFiltersMenu('close')}
                sx={css.drawer.wrapper}
            >
                <Filters.FilterHeader
                    title='Filtros'
                    bgColor={palette.lightBg}
                    onClose={() => handleToggleDrawerFiltersMenu('close')}
                />
                <Box sx={css.drawer.content.wrapper}>
                    <Filters.Search
                        filtersList={filtersList}
                    />
                </Box>
                {/* <Box sx={css.drawer.actions.wrapper}>
                    <Stack direction='row' spacing={2}>
                        <Button
                            fullWidth
                            type='button'
                            variant='text'
                            size='medium'
                            onClick={() => handleToggleDrawerFiltersMenu('close')}>
                            Limpar filtros
                        </Button>
                        <Button
                            fullWidth
                            type='button'
                            variant='contained'
                            size='medium'
                            className='MuiBlack'
                            onClick={() => handleToggleDrawerFiltersMenu('close')}>
                            Aplicar
                        </Button>
                    </Stack>
                </Box> */}
            </Drawer>
        </React.Fragment>
    );
};

export { MobileHeader };
