import { Button } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { sx } from './sx';

interface PaymentFormButtonProps {
    title: string;
    icon: string[];
    onClick: () => void;
}

export const PaymentFormButton: React.FC<PaymentFormButtonProps> = ({
    title, icon, onClick,
}: PaymentFormButtonProps) => {
    const { css } = sx();
    return (
        <Button
            sx={{ ...css.button }}
            onClick={onClick}>
            <I
                style={{ ...css.icon }}
                icon={icon as IconProp} />
            {title}
        </Button>
    );
};
