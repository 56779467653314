/* eslint-disable max-len */
import React from 'react';
import { Box, CircularProgress, Stack, Tab, Tabs, Typography } from '@mui/material';
// import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { styles } from '@utils';
import {
    Filters,
    TabPanel,
    HeaderPage,
    Display,
    ButtonIcon,
} from '@components';
import { sx } from './sx';
import { useUser } from '@hooks/user';
import { skeleton } from './skeleton';
import { ExpireSection } from './sections/expire';
import { BoxValue } from './components/box-value';

function a11yProps(index: number) {
    return {
        'id': 'simple-tab-' + index,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Extract: React.FC = () => {
    const { css } = sx();
    const mediaQuery = styles.mediaQueryCSS();
    const skel = skeleton();

    const user = useUser();

    const [valueTab, setValueTab] = React.useState(0);
    const [reload, setReload] = React.useState(false);

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setValueTab(newValue);
    };

    const loadApis = async () => {
        if (!user.wallet.walletValue.list.isLoaded) {
            user.wallet.walletValue.load();
        }
    };

    React.useEffect(() => {
        loadApis();
    }, []);

    return (
        <Box sx={{ ...css.box.wrapper.container }}>
            <HeaderPage
                icon={['far', 'memo']}
                noPadding
                titleAlign={'default'} title={'Meu Extrato'} />
            <Box sx={{ ...css.box.wrapper.values }}>
                <Stack
                    py={1}
                    px={mediaQuery.md ? 2 : 0}
                    direction={mediaQuery.md ? 'row' : 'column'}
                    spacing={2}>
                    <BoxValue
                        isLoaded={user.wallet.walletValue.list.isLoaded}
                        type={'POINTS'}
                        value={user.wallet.walletValue.list.data.availableBalance} />
                    <BoxValue
                        isLoaded={user.wallet.walletValue.list.isLoaded}
                        type={'POINTS_RELEASE'}
                        value={user.wallet.walletValue.list.data.pointsToRelease} />
                    {/* <BoxValue
                        isLoaded={user.wallet.walletValue.list.isLoaded}
                        type={'TOTAL_MONEY'} value={user.wallet.walletValue.list.data.money} /> */}
                </Stack>
            </Box>
            <Stack
                direction={'row'}
                spacing={1}
                py={1}
                px={2}>
                {(!user.wallet.movementHistory.list.isLoaded || !user.wallet.movementExpire.list.isLoaded) ?
                    skel.updateText :
                    <Typography sx={{ ...css.texts.info }} variant='body1'>
                        Informações capturadas em {new Date().toLocaleDateString()} às {new Date().toLocaleTimeString()} e sujeitas
                        a alterações até o fim do dia.
                    </Typography>
                }
                <Box>
                    {(!user.wallet.movementHistory.list.isLoaded || !user.wallet.movementExpire.list.isLoaded) ?
                        <Box p={1}>
                            <CircularProgress color='inherit' size={16} />
                        </Box> :
                        <ButtonIcon
                            onClick={() => {
                                setReload(!reload);
                                user.wallet.walletValue.load();
                            }}
                            active size={'small'} icon={'rotate-right'} />
                    }

                </Box>
            </Stack>
            <>
                <Box sx={{ ...css.box.wrapper.tabs }}>
                    <Box sx={{ ...css.box.tabs }}>
                        <Tabs
                            value={valueTab}
                            onChange={handleChangeTab}
                            sx={{ ...css.tabs }}>
                            <Tab label="Detalhes" {...a11yProps(0)} />
                            <Tab label="A expirar" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={valueTab} index={0}>
                        <Filters.ExtractPeriod reload={reload} />
                        <Display.WalletDetails />
                    </TabPanel>
                    <TabPanel value={valueTab} index={1}>
                        <Filters.ExtractMonths />
                        <ExpireSection />
                    </TabPanel>
                </Box>
            </>
        </Box>
    );
};

export { Extract };
